import { FC, ReactNode, useCallback, useState } from 'react'
import LoadingScreen from 'src/components/04_pages/LoadingScreen/LoadingScreen'
import User from 'src/structures/Classes/User'
import createGenericContext from 'src/utils/genericContext.utils'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

interface IUserContext {
    handleLogin: () => Promise<void>
    handleLogout: () => void
    user: User | null
    isLoading: boolean
    isLoggedIn: boolean
}

interface IUserProviderProperties {
    children: ReactNode
}

const [useUserContext, UserContextProvider] = createGenericContext<IUserContext>()

const UserProvider: FC<IUserProviderProperties> = ({ children }) => {
    const [user] = useState<User | null>(null)
    const [isLoading] = useState(false)

    const handleLogin = useCallback(() => {
        console.log('TODO: USER LOGIN')
        return Promise.resolve()
    }, [])

    const handleLogout = useCallback(() => {
        console.log('TODO: USER LOGOUT')
    }, [])

    const userContextValue = {
        handleLogin,
        handleLogout,
        user,
        isLoading,
        isLoggedIn: isNotNullOrUndefined(user),
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return <UserContextProvider value={userContextValue}>{children}</UserContextProvider>
}

export { UserProvider, useUserContext }
