import { FC } from 'react'
import { BrowserRouter } from 'react-router'
import { UserProvider } from './context/UserContext'
import Router from './components/00_fundaments/Router/Router'

type AppProperties = Record<string, never>

const App: FC<AppProperties> = () => {
    return (
        <BrowserRouter>
            <UserProvider>
                <Router />
            </UserProvider>
        </BrowserRouter>
    )
}

export default App
