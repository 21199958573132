import { yupResolver } from '@hookform/resolvers/yup'
import { ReactNode } from 'react'
import { DefaultValues, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

interface FormProps<T extends FieldValues> {
    schema: Yup.ObjectSchema<any>
    defaultValues: DefaultValues<T>
    onSubmit: SubmitHandler<T>
    children: ReactNode
}

const Form = <T extends Record<string, any>>({ schema, defaultValues, onSubmit, children }: FormProps<T>) => {
    const methods = useForm<T>({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'all',
    })

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='grid grid-cols-1 gap-6'>{children}</div>
            </form>
        </FormProvider>
    )
}

export default Form
