import { FC } from 'react'
import { Outlet } from 'react-router'

type IRootLayoutProperties = Record<string, null>

const RootLayout: FC<IRootLayoutProperties> = () => (
    <main className='w-full mx-auto max-w-screen-lg'>
        <Outlet />
    </main>
)

export default RootLayout
