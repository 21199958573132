import { FC, ReactNode } from 'react'

interface IHeroProperties {
    children?: ReactNode
}

const Hero: FC<IHeroProperties> = ({ children }) => {
    return (
        <section className='w-full flex items-center justify-between p-6'>
            <div className='w-full'>{children}</div>
        </section>
    )
}
export default Hero
