import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import LoadingSpinner from 'src/components/01_atoms/LoadingSpinner/LoadingSpinner'

interface ILoadingScreenProperties {
    className?: string
}

const LoadingScreen: FC<ILoadingScreenProperties> = ({ className }) => (
    <div className={twMerge('fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2', className)}>
        <LoadingSpinner className='w-12 h-12' />
    </div>
)

export default LoadingScreen
