import { FC } from 'react'
import { Link } from 'react-router'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { twMerge } from 'tailwind-merge'

interface INotFoundProperties {
    className?: string
}

const NotFound: FC<INotFoundProperties> = ({ className }) => (
    <div className={twMerge('fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center', className)}>
        <h1 className='h1 mb-4'>Nothing here!</h1>
        <Link
            to={RoutePath.ROOT}
            className='text-red-500'>
            Go to dashboard
        </Link>
    </div>
)

export default NotFound
