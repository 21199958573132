import React from 'react'

// Reasoning behind custom context wrapper: https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
const createGenericContext = <T>(defaultValue?: T) => {
    // Create a context with a generic parameter or undefined
    const genericContext = React.createContext<T | undefined>(defaultValue)

    // Check if the value provided to the context is defined or throw an error
    const useGenericContext = () => {
        const contextIsDefined = React.useContext(genericContext)
        if (contextIsDefined === undefined) {
            throw new Error('Context not loaded')
        }
        return contextIsDefined
    }

    return [useGenericContext, genericContext.Provider] as const
}

export default createGenericContext
