import { FC, ReactNode } from 'react'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

interface IPageProperties {
    header?: ReactNode
    children: ReactNode
    footer?: ReactNode
}

const Page: FC<IPageProperties> = ({ header, children, footer }) => (
    <section>
        {isNotNullOrUndefined(header) ? header : null}
        <div>{children}</div>
        {isNotNullOrUndefined(footer) ? footer : null}
    </section>
)

export default Page
