import { FC } from 'react'
import { Route, Routes } from 'react-router'
import Login from 'src/components/04_pages/Login/Login'
import RoutePath from 'src/structures/Enums/RoutePath.enum'

type IUnAuthenticatedRoutesProperties = Record<string, null>

const UnAuthenticatedRoutes: FC<IUnAuthenticatedRoutesProperties> = () => (
    <Routes>
        <Route
            index
            path={RoutePath.ROOT}
            element={<Login />}
        />
        <Route
            path={RoutePath.PAGE_NOT_FOUND}
            element={<Login />}
        />
    </Routes>
)

export default UnAuthenticatedRoutes
