import { FC } from 'react'
import { Route, Routes } from 'react-router'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import RootLayout from '../RootLayout/RootLayout'
import MenuScannerDashboard from 'src/components/04_pages/MenuScannerDashboard/MenuScannerDashboard'
import NotFound from 'src/components/04_pages/NotFound/NotFound'
import MenuScannerLocation from 'src/components/04_pages/MenuScannerLocation/MenuScannerLocation'
import MenuScannerUpload from 'src/components/04_pages/MenuScannerUpload/MenuScannerUpload'

type IAuthenticatedRoutesProperties = Record<string, null>

const AuthenticatedRoutes: FC<IAuthenticatedRoutesProperties> = () => (
    <Routes>
        <Route element={<RootLayout />}>
            <Route
                index
                path={RoutePath.ROOT}
                element={<MenuScannerDashboard />}
            />
            <Route
                path={RoutePath.MENU_SCANNER_LOCATION}
                element={<MenuScannerLocation />}
            />
            <Route
                path={RoutePath.MENU_SCANNER_UPLOAD}
                element={<MenuScannerUpload />}
            />
            <Route
                path={RoutePath.PAGE_NOT_FOUND}
                element={<NotFound />}
            />
        </Route>
    </Routes>
)

export default AuthenticatedRoutes
