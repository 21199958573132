import { FC } from 'react'
import { useUserContext } from '../../../context/UserContext'
import AuthenticatedRoutes from '../AuthenticatedRoutes/AuthenticatedRoutes'
import UnAuthenticatedRoutes from '../UnAuthenticatedRoutes/UnAuthenticatedRoutes'

type IRouterProperties = Record<string, null>

const Router: FC<IRouterProperties> = () => {
    const { isLoggedIn } = useUserContext()

    if (!isLoggedIn) {
        return <AuthenticatedRoutes />
    }

    return <UnAuthenticatedRoutes />
}

export default Router
