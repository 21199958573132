import { FC, InputHTMLAttributes } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
    validation?: Record<string, any>
}

const Input: FC<InputProps> = ({ name, label, required = false, className = '', ...rest }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    return (
        <label
            className='block'
            htmlFor={name}>
            {isNotNullOrUndefined(label) ? <span className='text-grey-700'>{label}</span> : null}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <input
                        {...field}
                        {...rest}
                        id={name}
                        className={`mt-1 h-12 block w-full rounded-md ${isNotNullOrUndefined(errors[name]) ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                    />
                )}
            />
            {isNotNullOrUndefined(errors[name]) ? (
                <div className='text-sm text-red-500 mt-2'>{errors[name]?.message?.toString()}</div>
            ) : null}
        </label>
    )
}

export default Input
