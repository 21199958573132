import { FC, useCallback } from 'react'

interface IChevronIconProperties {
    direction: 'left' | 'right' | 'up' | 'down'
    className?: string
}

const ChevronIcon: FC<IChevronIconProperties> = ({ className, direction = 'left' }) => {
    const getRotation = useCallback(() => {
        if (direction === 'up') return 90
        if (direction === 'right') return 90 * 2
        if (direction === 'down') return 90 * 3
        return 0
    }, [direction])

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            style={{ transform: `rotate(${getRotation()}deg)` }}
            className={className}>
            <path
                fillRule='evenodd'
                d='M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z'
                clipRule='evenodd'
            />
        </svg>
    )
}

export default ChevronIcon
