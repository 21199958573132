import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import Button, { ButtonStyle } from 'src/components/01_atoms/Button/Button'
import PlusIcon from 'src/components/01_atoms/Icon/PlusIcon'
import Header from 'src/components/02_molecules/Header/Header'
import Hero from 'src/components/02_molecules/Hero/Hero'
import MenuScannerHistory from 'src/components/03_organisms/MenuScannerHistory/MenuScannerHistory'
import Page from 'src/components/03_organisms/Page/Page'
import { useUserContext } from 'src/context/UserContext'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

type IMenuScannerDashboardProperties = Record<string, null>

const MenuScannerDashboard: FC<IMenuScannerDashboardProperties> = () => {
    const { user } = useUserContext()
    const navigate = useNavigate()

    const handleNewScanSession = useCallback(() => {
        // TODO: post session request to API
        navigate(RoutePath.MENU_SCANNER_LOCATION.replace(':sessionId', '1234'))
    }, [navigate])

    return (
        <Page
            header={<Header title='Homepage' />}
            footer={
                <div className='h-28'>
                    <div className='fixed flex items-center justify-center bottom-0 left-0 right-0 p-6 animate-animateUp'>
                        <Button
                            onClick={handleNewScanSession}
                            className='w-full max-w-screen-lg'
                            buttonStyle={ButtonStyle.PRIMARY}>
                            <PlusIcon className='size-6 m-0 mr-2' />
                            New scansession
                        </Button>
                    </div>
                </div>
            }>
            <Hero>
                <h1 className='h3 text-center'>{`Hello${isNotNullOrUndefined(user) ? ', ' + user?.firstName : ''}!`}</h1>
            </Hero>
            <MenuScannerHistory />
        </Page>
    )
}

export default MenuScannerDashboard
