import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

import Language from './structures/Enums/Language.enum'

// eslint-disable-next-line no-void
void i18n
    // use translation files
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: `/locales/{{lng}}.json`,
        },
        react: {
            bindI18n: 'loaded languageChanged',
            bindI18nStore: 'added',
            useSuspense: true,
        },
        debug: false,
        fallbackLng: [Language.EN, Language.NL, Language.FR],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            skipOnVariables: false,
        },
        returnEmptyString: false,
    })

// eslint-disable-next-line no-restricted-exports
export { default } from 'i18next'
