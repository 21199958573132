import { FC } from 'react'
import LoginForm from 'src/components/03_organisms/LoginForm/LoginForm'

type ILoginProperties = Record<string, null>

const Login: FC<ILoginProperties> = () => (
    <div>
        <LoginForm />
    </div>
)

export default Login
