import { FC, useCallback, useState } from 'react'
import { DefaultValues } from 'react-hook-form'
import Input from 'src/components/01_atoms/Input/Input'
import Form from 'src/components/02_molecules/Form/Form'

import * as Yup from 'yup'

type ILoginFormProperties = Record<string, null>

interface ILoginFormValues {
    email: string
    password: string
}

const LoginForm: FC<ILoginFormProperties> = () => {
    const [isSubmitting, setISubmitting] = useState(false)

    const schema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().min(4, 'Password must be at least 4 characters').required('Password is required'),
    })

    const defaultValues: DefaultValues<ILoginFormValues> = {
        email: '',
        password: '',
    }

    const onSubmit = useCallback((data: ILoginFormValues) => {
        setISubmitting(true)
        console.log(data)
    }, [])

    return (
        <Form
            schema={schema}
            defaultValues={defaultValues}
            onSubmit={onSubmit}>
            <Input
                name='email'
                label='Email'
                type='email'
                disabled={isSubmitting}
                placeholder='Email'
            />
            <Input
                name='password'
                label='Password'
                type='password'
                disabled={isSubmitting}
                placeholder='Password'
            />
            <button
                disabled={isSubmitting}
                type='submit'
                className='bg-slate-600 text-white p-2 rounded-lg'>
                Submit
            </button>
        </Form>
    )
}

export default LoginForm
