import { FC } from 'react'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

type IMenuScannerHistoryProperties = Record<string, null>

const MenuScannerHistory: FC<IMenuScannerHistoryProperties> = () => {
    const history = [
        {
            id: 10,
            location: 'Bar Bidon',
            date: '22/10/2024',
        },
        {
            id: 11,
            location: 'Claw',
            date: '22/10/2024',
        },
    ]

    if (isNotNullOrUndefined(history)) {
        return (
            <section className='rounded-xl mx-2 bg-white shadow-sm'>
                <table className='table-auto min-w-full rounded-xl overflow-hidden'>
                    <thead className='border-b'>
                        <tr className='bg-gray-50'>
                            <th className='p-5 py-2 text-left text-sm leading-6 font-semibold text-gray-900'>
                                Location
                            </th>
                            <th className='p-5 py-2 text-right text-sm leading-6 font-semibold text-gray-900'>Date</th>
                        </tr>
                    </thead>
                    <tbody className='divide-y divide-dashed'>
                        {history.map((scan) => (
                            <tr key={scan.id}>
                                <td className='p-5 whitespace-nowrap text-sm leading-6 text-gray-900'>
                                    {scan.location}
                                </td>
                                <td className='p-5 whitespace-nowrap text-right text-sm leading-6 text-gray-900'>
                                    {scan.date}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot className='border-t border-dashed'>
                        <tr>
                            <td
                                colSpan={2}
                                className='p-2 bg-gray-50 whitespace-nowrap text-center text-sm leading-6 text-gray-500'>
                                {`${history.length} previous scan sessions`}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </section>
        )
    }

    return (
        <section className='flex items-center min-h-60 rounded-xl m-2 p-6 text-center text-gray-400 bg-white shadow-sm'>
            <div>
                <h3 className='h3'>No scans yet!</h3>
                <p>Previously scanned menu's will appear here.</p>
            </div>
        </section>
    )
}

export default MenuScannerHistory
