import { FC, useCallback } from 'react'
import { Navigate, useParams } from 'react-router'
import Button, { ButtonStyle } from 'src/components/01_atoms/Button/Button'
import Header from 'src/components/02_molecules/Header/Header'
import Hero from 'src/components/02_molecules/Hero/Hero'
import Page from 'src/components/03_organisms/Page/Page'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { isNotEmpty, isNotNullOrUndefined } from 'src/utils/guards.utils'

type IMenuScannerLocationProperties = Record<string, null>

const MenuScannerLocation: FC<IMenuScannerLocationProperties> = () => {
    const { sessionId } = useParams()

    const handleStartScanning = useCallback(() => {
        console.log('start scanning')
    }, [])

    if (isNotNullOrUndefined(sessionId) && isNotEmpty(sessionId)) {
        return (
            <Page
                header={
                    <Header
                        backButton={RoutePath.ROOT}
                        title='Homepage'
                    />
                }
                footer={
                    <div className='h-28'>
                        <div className='fixed flex items-center justify-center bottom-0 left-0 right-0 p-6'>
                            <Button
                                disabled
                                className='w-full max-w-screen-lg'
                                buttonStyle={ButtonStyle.PRIMARY}
                                onClick={handleStartScanning}>
                                Start scanning
                            </Button>
                        </div>
                    </div>
                }>
                <Hero>
                    <h1 className='h3 text-center'>Where are you?</h1>
                </Hero>
            </Page>
        )
    }

    return (
        <Navigate
            to={RoutePath.ROOT}
            replace
        />
    )
}

export default MenuScannerLocation
