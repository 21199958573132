import { forwardRef, MouseEvent, ReactNode, Ref } from 'react'
import { twMerge } from 'tailwind-merge'

import { isNotNullOrUndefined } from '../../../utils/guards.utils'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

export enum ButtonStyle {
    PRIMARY = 'Primary',
    SECONDARY = 'Secondary',
    DANGER = 'Danger',
    SUCCESS = 'Success',
}

interface IButtonProperties {
    buttonStyle?: ButtonStyle
    children: ReactNode
    className?: string
    disabled?: boolean
    href?: string
    loading?: boolean
    onClick?: (event: MouseEvent) => void
    outline?: boolean
    tabIndex?: number
    type?: 'submit' | 'button'
}

const STYLE_MAP = {
    [ButtonStyle.PRIMARY]: 'rose',
    [ButtonStyle.SECONDARY]: 'slate',
    [ButtonStyle.DANGER]: 'rose',
    [ButtonStyle.SUCCESS]: 'green',
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, IButtonProperties>(
    (
        {
            buttonStyle = ButtonStyle.PRIMARY,
            children,
            className,
            disabled = false,
            href,
            loading = false,
            outline = false,
            onClick,
            tabIndex,
            type = 'button',
        },
        reference
    ) => {
        const isHyperLink = isNotNullOrUndefined(href)
        const color = STYLE_MAP[buttonStyle]
        const baseClasses =
            'px-3 py-4 font-medium text-sm inline-flex items-center justify-center leading-5 border rounded-lg shadow-sm transition duration-150 ease-in-out'
        const disabledClasses =
            'disabled:cursor-not-allowed disabled:text-slate-400 disabled:bg-slate-100 disabled:border-slate-200'

        const dynamicClasses = twMerge(
            outline
                ? `border-${color}-500 hover:border-${color}-600 bg-white hover:bg-gray-100 text-${color}-500 hover:text-${color}-600`
                : `bg-${color}-500 hover:bg-${color}-600 border-${color}-500 hover:border-${color}-600 text-white`,
            disabled || loading ? '' : ''
        )

        const Element = isHyperLink ? 'a' : 'button'

        return (
            <Element
                ref={reference as Ref<HTMLButtonElement & HTMLAnchorElement>}
                onClick={!isHyperLink && !disabled && !loading ? onClick : undefined}
                disabled={disabled || loading}
                href={href}
                type={isHyperLink ? undefined : type}
                tabIndex={tabIndex}
                aria-busy={loading ?? undefined}
                className={twMerge(baseClasses, disabledClasses, dynamicClasses, className)}>
                {loading && <LoadingSpinner className='mr-2 text-slate-400' />}
                {children}
            </Element>
        )
    }
)

Button.displayName = 'Button'
export default Button
